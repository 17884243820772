/* eslint-disable sort-keys, sort-keys-fix/sort-keys-fix */

const spacing = {
    xs: `0.25rem`,
    sm: `0.5rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2rem`,
    xxl: `2.5rem`,
    xxxl: `4rem`,
};

export default spacing;
